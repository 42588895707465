import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1227.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">

        <path d="M3395 7230 c-12 -4 -26 -14 -33 -22 -16 -20 -18 -3978 -1 -4009 5
-11 21 -24 35 -29 34 -13 3125 -13 3256 0 117 11 263 57 348 109 200 122 324
282 397 517 l28 89 3 1123 3 1122 -486 0 -485 0 0 -980 c0 -954 -1 -981 -19
-999 -11 -11 -39 -22 -63 -26 -24 -3 -497 -4 -1053 -3 l-1010 3 -3 1078 -2
1077 235 0 c179 0 237 3 240 13 3 6 6 222 7 480 l3 467 -690 -1 c-379 0 -699
-4 -710 -9z"/>
<path d="M4961 7213 c-12 -10 -26 -36 -31 -58 -6 -24 -10 -619 -10 -1456 0
-1178 3 -1419 14 -1428 9 -8 147 -10 482 -9 l469 3 3 223 2 222 215 0 215 0 0
490 0 490 -215 0 -215 0 0 290 0 290 1054 0 c1022 0 1054 -1 1077 -19 24 -19
24 -20 27 -265 l3 -246 -25 -25 -25 -25 -216 0 -215 0 0 -490 0 -490 213 0
c151 -1 217 -4 228 -13 35 -27 39 -53 39 -282 0 -246 -6 -276 -56 -295 -14 -6
-115 -10 -225 -10 l-199 0 0 -77 c0 -318 -166 -641 -423 -823 -33 -23 -44 -36
-35 -42 7 -4 272 -6 588 -5 l575 3 99 27 c107 29 196 73 296 147 149 111 246
252 307 447 l28 88 0 365 0 365 -28 90 c-34 107 -75 187 -142 276 -50 66 -141
155 -202 198 -18 12 -33 26 -33 30 0 3 14 15 31 25 48 29 168 145 214 209 59
80 111 187 137 282 21 76 23 99 23 440 0 341 -2 364 -23 440 -82 296 -305 522
-598 607 l-79 22 -1661 3 c-1576 3 -1662 2 -1683 -14z"/>
<path d="M6800 2535 l0 -265 65 0 65 0 -2 263 -3 262 -62 3 -63 3 0 -266z"/>
<path d="M3390 2515 l0 -245 135 0 c73 0 157 5 185 11 69 15 129 62 163 129
25 48 28 63 24 120 -5 75 -22 115 -74 165 -54 52 -110 65 -285 65 l-148 0 0
-245z m308 119 c58 -38 77 -118 44 -184 -23 -47 -72 -70 -149 -70 l-63 0 0
141 0 142 73 -7 c39 -4 82 -14 95 -22z"/>
<path d="M3980 2516 l0 -246 163 0 c177 0 221 8 266 46 56 47 54 123 -5 182
l-35 35 20 21 c37 40 45 81 24 123 -36 72 -52 78 -254 81 l-179 3 0 -245z
m285 134 c18 -15 20 -51 3 -68 -7 -7 -40 -12 -80 -12 l-68 0 0 49 0 49 65 -3
c36 -2 72 -8 80 -15z m29 -196 c21 -20 20 -43 -1 -66 -13 -14 -31 -18 -95 -18
l-78 0 0 50 0 50 79 0 c59 0 84 -4 95 -16z"/>
<path d="M5850 2710 l0 -49 73 -3 72 -3 3 -192 2 -193 70 0 70 0 2 193 3 192
73 3 72 3 0 49 0 50 -220 0 -220 0 0 -50z"/>
<path d="M5380 2690 c0 -47 -2 -50 -25 -50 -23 0 -25 -3 -25 -50 0 -47 2 -50
25 -50 25 0 25 -1 25 -82 0 -133 41 -189 141 -192 30 0 66 3 81 7 l27 7 -15
44 c-14 41 -19 45 -52 48 l-37 3 -3 83 -3 82 41 0 40 0 0 50 0 50 -40 0 c-39
0 -40 1 -41 38 -1 20 -1 43 0 50 1 8 -18 12 -69 12 l-70 0 0 -50z"/>
<path d="M4626 2645 c-80 -28 -131 -104 -127 -192 3 -104 58 -162 173 -183 66
-13 148 2 184 33 l25 22 -33 32 -33 32 -43 -15 c-65 -22 -142 5 -142 50 0 3
63 6 140 6 l140 0 0 38 c-1 136 -145 227 -284 177z m137 -102 c35 -33 21 -43
-58 -43 -41 0 -75 3 -75 6 0 3 5 14 11 25 21 40 83 46 122 12z"/>
<path d="M5058 2651 c-78 -26 -120 -91 -98 -155 15 -46 44 -63 129 -77 70 -12
99 -28 86 -49 -9 -15 -103 -12 -150 5 -22 8 -41 15 -41 15 -2 0 -34 -80 -34
-85 0 -11 42 -25 105 -35 140 -22 245 29 245 120 0 59 -37 94 -116 109 -32 7
-69 14 -81 17 -31 6 -29 32 4 45 25 9 82 2 125 -16 12 -5 21 1 32 24 21 41 20
58 -5 72 -28 14 -166 21 -201 10z"/>
<path d="M6446 2645 c-79 -28 -126 -98 -126 -188 0 -61 34 -126 84 -156 76
-48 216 -46 272 2 l25 22 -28 34 -29 33 -47 -16 c-58 -19 -101 -12 -128 23
l-21 26 141 3 141 3 0 39 c0 136 -144 225 -284 175z m143 -113 l24 -27 -78 -3
c-42 -2 -79 -1 -81 1 -10 9 10 39 35 52 34 19 70 11 100 -23z"/>
<path d="M7137 2650 c-80 -24 -137 -104 -137 -192 0 -94 65 -167 166 -188 68
-14 153 1 192 35 l27 24 -31 31 -31 32 -47 -16 c-50 -17 -90 -13 -118 12 -42
36 -24 42 118 42 l136 0 -4 54 c-6 71 -38 119 -102 151 -52 26 -114 31 -169
15z m132 -118 l24 -27 -78 -3 c-42 -2 -79 -1 -81 1 -10 10 10 37 39 51 39 19
64 13 96 -22z"/>
<path d="M7591 2646 c-166 -60 -182 -281 -26 -358 80 -40 193 -28 250 27 19
18 35 37 35 44 0 6 -22 22 -49 35 l-48 25 -23 -24 c-28 -30 -78 -33 -110 -7
-15 12 -24 33 -27 64 -5 40 -2 50 20 72 33 33 88 35 117 6 11 -11 25 -20 31
-20 17 0 89 43 89 53 0 20 -68 76 -105 86 -51 14 -111 13 -154 -3z"/>
<path d="M8016 2645 c-80 -28 -129 -99 -129 -185 0 -118 83 -193 213 -193 111
0 188 56 209 152 34 160 -128 285 -293 226z m143 -121 c30 -38 28 -98 -4 -129
-53 -53 -135 -15 -135 63 0 57 31 92 80 92 30 0 43 -6 59 -26z"/>
<path d="M8548 2641 c-31 -17 -38 -18 -38 -6 0 12 -14 15 -65 15 l-65 0 0
-190 0 -190 64 0 63 0 5 113 c3 61 8 119 12 129 4 9 21 22 37 28 24 10 33 10
54 -4 24 -16 25 -19 25 -142 l0 -125 68 3 67 3 3 117 c3 113 4 118 29 138 32
25 48 25 73 0 18 -18 20 -33 20 -140 l0 -120 71 0 71 0 -4 143 c-3 125 -6 146
-25 177 -28 45 -53 60 -111 67 -46 5 -91 -8 -133 -38 -13 -10 -23 -8 -49 11
-47 33 -123 38 -172 11z"/>
<path d="M5367 2093 c-4 -3 -7 -147 -7 -320 l0 -313 710 0 710 0 0 320 0 320
-703 0 c-387 0 -707 -3 -710 -7z m435 -228 c14 -4 34 -16 44 -26 17 -19 17
-21 -3 -41 -20 -19 -22 -20 -44 -4 -13 9 -34 16 -47 16 -33 0 -72 -43 -72 -80
0 -36 38 -80 70 -80 12 0 34 6 49 14 25 14 29 13 45 -6 16 -19 17 -21 0 -39
-25 -28 -86 -42 -132 -30 -104 28 -144 169 -68 240 27 26 94 52 116 46 8 -2
27 -7 42 -10z m310 -11 c69 -35 97 -141 52 -200 -36 -48 -68 -66 -120 -67 -88
-1 -142 44 -151 126 -14 118 109 196 219 141z m326 0 c20 -13 25 -13 44 1 27
18 106 19 128 2 42 -33 50 -59 50 -164 l0 -103 -40 0 -40 0 0 94 c0 117 -21
148 -81 116 -17 -10 -19 -22 -19 -110 l0 -100 -35 0 -35 0 0 88 c0 96 -14 132
-52 132 -46 0 -58 -26 -58 -127 l0 -93 -35 0 -35 0 0 139 0 139 93 0 c65 1 99
-4 115 -14z m-878 -199 c16 -19 5 -61 -17 -67 -25 -7 -42 1 -53 23 -23 43 39
82 70 44z"/>
<path d="M6004 1798 c-67 -32 -42 -148 32 -148 67 0 98 87 49 135 -26 27 -47
30 -81 13z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
